import Rest from '../Rest'

export default class RoleService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/roles'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  destroyRoleUser(roleId: Number, userId: Number) {
    return this.delete(`${roleId}/user/${userId}`)
  }

  attachRoleUser(roleId: Number, userId: Number) {
    return this.post(`${roleId}/attach`, {
      user_id: userId
    })
  }

  attachPermissions(roleId: Number, permissions: Array<any>) {
    return this.post(`${roleId}/permissions/attach`, {permissions: permissions})
  }

  clone(roleId: number) {
    return this.post(`${roleId}/clone`)
  }

  destroyPermissions(roleId: Number, permissionIds) {
    return this.put(`${roleId}/permissions/delete`, {
      permissions: permissionIds
    })
  }
}
