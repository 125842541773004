var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex flex-wrap-reverse items-center" }, [
        _c(
          "div",
          {
            staticClass:
              "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
            on: { click: _vm.addNew },
          },
          [
            _c("feather-icon", {
              attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
            }),
            _c("span", { staticClass: "ml-2 text-base text-primary" }, [
              _vm._v(_vm._s(_vm.$t("AddNew"))),
            ]),
          ],
          1
        ),
      ]),
      _c("Grid", {
        ref: "grid",
        attrs: { service: _vm.service, route_name: "roles" },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function (actions) {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-row gap-2" },
                  [
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "roles.edit",
                          expression: "`roles.edit`",
                        },
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            content: "Editar grupo",
                            delay: { show: 100, hide: 100 },
                          },
                          expression:
                            "{\n            content: 'Editar grupo',\n            delay: { show: 100, hide: 100 }\n          }",
                          modifiers: { top: true },
                        },
                      ],
                      attrs: {
                        icon: "EditIcon",
                        svgClasses:
                          "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.edit(actions.row.id)
                        },
                      },
                    }),
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            content: "Clonar grupo",
                            delay: { show: 100, hide: 100 },
                          },
                          expression:
                            "{\n            content: 'Clonar grupo',\n            delay: { show: 100, hide: 100 }\n          }",
                          modifiers: { top: true },
                        },
                      ],
                      attrs: {
                        icon: "CopyIcon",
                        svgClasses:
                          "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirmClone(actions.row.id)
                        },
                      },
                    }),
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "roles.delete",
                          expression: "`roles.delete`",
                        },
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            content: "Excluir grupo",
                            delay: { show: 100, hide: 100 },
                          },
                          expression:
                            "{\n            content: 'Excluir grupo',\n            delay: { show: 100, hide: 100 }\n          }",
                          modifiers: { top: true },
                        },
                      ],
                      attrs: {
                        icon: "Trash2Icon",
                        svgClasses:
                          "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.destroy(actions.row)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }