
<template>
  <div>
    <div class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{$t('AddNew')}}</span>
      </div>
    </div>
    <Grid :service="service" route_name="roles" :ref="'grid'">
      <template v-slot:actions="actions">
        <div class="flex flex-row gap-2">
          <feather-icon
            v-permission="`roles.edit`"
            icon="EditIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
            v-tooltip.top="{
              content: 'Editar grupo',
              delay: { show: 100, hide: 100 }
            }"
            @click="edit(actions.row.id)"
          />
          <feather-icon
            icon="CopyIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
            v-tooltip.top="{
              content: 'Clonar grupo',
              delay: { show: 100, hide: 100 }
            }"
            @click="confirmClone(actions.row.id)"
          />
          <feather-icon
            v-permission="`roles.delete`"
            icon="Trash2Icon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
            v-tooltip.top="{
              content: 'Excluir grupo',
              delay: { show: 100, hide: 100 }
            }"
            @click="destroy(actions.row)"
          />
        </div>
      </template>
    </Grid>
  </div>
</template>


<script>
import RoleService from '@/services/api/RoleService'
import FeatherIcon from '@/components/FeatherIcon.vue'

export default {
  components: { FeatherIcon },
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/roles/create')
    },
    grid() {
      return this.$refs['grid']
    },
    edit(id) {
      const grid = this.grid()
      if (grid) {
        grid.edit(id)
      }
    },
    destroy(data) {
      const grid = this.grid()
      if (grid) {
        grid.destroy(data)
      }
    },
    confirmClone(id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-clonar-este-grupo'),
        accept: () => {
          this.clone(id)
        }
      })
    },
    clone(id) {
      this.$vs.loading()
      this.service.clone(id).then(
        response => {
          this.$router.push(`/roles/${response.id}/edit`)
          this.$vs.loading.close()
          this.notifySuccess(
            this.$vs,
            this.$t('grupo-clonado-com-sucesso'),
            2000,
            'center-top'
          )
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
  },
  beforeMount() {
    this.service = RoleService.build(this.$vs)
  }
}
</script>